import { getHslAnimationFromHsl, getRangeValue, itemFromSingleOrMultiple, rangeColorToHsl, } from "tsparticles-engine";
import { updateStrokeColor } from "./Utils";
export class StrokeColorUpdater {
    constructor(container) {
        this.container = container;
    }
    init(particle) {
        const container = this.container, options = particle.options;
        const stroke = itemFromSingleOrMultiple(options.stroke, particle.id, options.reduceDuplicates);
        particle.strokeWidth = getRangeValue(stroke.width) * container.retina.pixelRatio;
        particle.strokeOpacity = getRangeValue(stroke.opacity ?? 1);
        particle.strokeAnimation = stroke.color?.animation;
        const strokeHslColor = rangeColorToHsl(stroke.color) ?? particle.getFillColor();
        if (strokeHslColor) {
            particle.strokeColor = getHslAnimationFromHsl(strokeHslColor, particle.strokeAnimation, container.retina.reduceFactor);
        }
    }
    isEnabled(particle) {
        const color = particle.strokeAnimation, { strokeColor } = particle;
        return (!particle.destroyed &&
            !particle.spawning &&
            !!color &&
            ((strokeColor?.h.value !== undefined && strokeColor.h.enable) ||
                (strokeColor?.s.value !== undefined && strokeColor.s.enable) ||
                (strokeColor?.l.value !== undefined && strokeColor.l.enable)));
    }
    update(particle, delta) {
        if (!this.isEnabled(particle)) {
            return;
        }
        updateStrokeColor(particle, delta);
    }
}
